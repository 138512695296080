import React from 'react';
import './LandingPage.css';
import logo from '../images/logo.jpg';

function LandingPage() {
    return (
        <div className="landing">
            <header className="header">
                <div className="header-content">
                    <img src={logo} alt="EZ Choice Lawn Care Logo" className="logo" />
                    <div className="text-content">
                        <h1>Welcome to EZ Choice Lawn Care</h1>
                        <p>Your trusted partner in lawn care excellence.</p>
                    </div>
                </div>
            </header>
            <section className="services">
                <h2>Our Services</h2>
                <ul>
                    <li>Mowing and Edging</li>
                    <li>Landscaping and Design</li>
                    <li>Seasonal Cleanup</li>
                    <li>Irrigation Management</li>
                </ul>
            </section>
        </div>
    );
}

export default LandingPage;
