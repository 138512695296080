import logo from './logo.svg';
import './App.css';
import LandingPage from './Components/LandingPage';
import QuoteForm from './Components/QuoteForm';

function App() {
  return (
      <div className="App">
          <LandingPage />
          <QuoteForm />
      </div>
  );
}

export default App;
