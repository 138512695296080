import React, { useState } from 'react';
import './QuoteForm.css'; // Ensure to create this CSS file

function QuoteForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        service: ''
    });

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        alert('Thank you! We will get back to you soon.');
    };

    return (
        <div className="quote-form">
            <h2>Request a Quote</h2>
            <form onSubmit={handleSubmit}>
                <label>Name:
                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                </label>
                <label>Email:
                    <input type="email" name="email" value={formData.email} onChange={handleChange} />
                </label>
                <label>Service Requested:
                    <select name="service" value={formData.service} onChange={handleChange}>
                        <option value="mowing">Mowing</option>
                        <option value="landscaping">Landscaping</option>
                        <option value="maintenance">Maintenance</option>
                    </select>
                </label>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default QuoteForm;
